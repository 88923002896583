import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
// Material components
import {Button, Grid, IconButton, InputAdornment, Typography, withStyles} from "@material-ui/core";

// Material icons
// import {
//     ArrowUpward as ArrowUpwardIcon,
// } from '@material-ui/icons';
// Shared components
import {SearchInput} from "components";

// Component styles
import styles from "./styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import {ArrowUpward as ArrowUpwardIcon, CloseOutlined as CloseButton} from "@material-ui/icons";

import CreatePumpSingleSignOn from "../CreatePumpSingleSignOn/CreatePumpSingleSignOn";
import secureLocalStorage from "react-secure-storage";

class UsersToolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchString: "",
            disabled: true
        };
    }

    onSearch = searchTerm => {
        this.setState({
            searchString: searchTerm,
            disabled: true
        });
        const {searchedTerm} = this.props;
        searchedTerm(searchTerm);
    };

    onPinCode = searchTerm => {
        this.setState({
            searchString: searchTerm,
            disabled: searchTerm.length < 1
        });
    };

    onSelectRefueller = refueller => {
        const {selectedRefueller} = this.props;
        selectedRefueller(refueller);
    };

    onSelectedCity = city => {
        const {selectedCity} = this.props;
        selectedCity(city);
    };

    handleDrawerOpen = () => {
        this.setState({drawerIsOpen: true});
    };

    handleDrawerClose = () => {
        this.setState({drawerIsOpen: false});
        // window.location.reload();
    };

    redirectOnSuccess = () => {
        this.setState({drawerIsOpen: false});
        this.props.partnerCreationSuccessReloadRequest();
    };

    handleClickToSearch = () => {
        const {onSearchPinCode} = this.props;
        onSearchPinCode(this.state.searchString);
    };

    render() {
        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);

        const exportCSV = () => {
            const csvRow = [];
            const A = [
                [
                    "Pump Name",
                    "Mobile",
                    "Email",
                    "City Name",
                    "Owned by",
                    "Pan Card",
                    "Gst Number",
                    "Zone",
                    "Region",
                    "partner type",
                    "monetized",
                    "Agreement start date",
                    "Repos share",
                    "Pump state code",
                    "Pump brand"
                ]
            ];
            const allPartnerData = this.props.allPartners;

            let firstNameForFileName = "";
            let lastNameForFileName = "";

            for (let item = 0; item < allPartnerData.length; item++) {
                A.push([
                    allPartnerData[item].name,
                    allPartnerData[item].mobile,
                    allPartnerData[item].email,
                    allPartnerData[item].city_name,
                    allPartnerData[item].owned_by,
                    allPartnerData[item].pan_card,
                    allPartnerData[item].gst_number,
                    allPartnerData[item].zone[0].zone,
                    allPartnerData[item].region[0].region,
                    allPartnerData[item].partner_type,
                    allPartnerData[item].monetized,
                    allPartnerData[item].asd,
                    allPartnerData[item].repos_share,
                    allPartnerData[item].pumps[0].pump_state_code,
                    allPartnerData[item]?.pumps[0]?.pump_brand
                ]);
                firstNameForFileName = allPartnerData[0].id;
                lastNameForFileName = allPartnerData[allPartnerData.length - 1].id;
            }

            for (let i = 0; i < A.length; ++i) {
                csvRow.push(A[i].join(","));
            }

            const today = new Date();
            const date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate();
            const time =
                today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date + "_" + time;

            const csvString = csvRow.join("%0A");

            const a = document.createElement("a");

            a.href = "data:attachement/csv," + csvString;

            a.target = "_Blank";

            a.download =
                "Partner List" +
                firstNameForFileName +
                "_to_" +
                lastNameForFileName +
                "at" +
                dateTime +
                ".csv";

            document.body.appendChild(a);

            a.click();
        };

        return (
            <div className={rootClassName}>
                <div>
                    <Typography
                        className={classes.nameText}
                        variant="h1"
                        style={{
                            fontWeight: 300,
                            color: "gray",
                            marginBottom: "4%"
                        }}
                    >
                        All Partners
                    </Typography>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <SearchInput
                            className={classes.searchInput}
                            placeholder="Search By Partner, Mobile, Email"
                            getsearchterm={this.onSearch}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <SearchInput
                            className={classes.searchInput}
                            type={"number"}
                            placeholder="Search By Pincode"
                            getsearchterm={this.onPinCode}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Button
                                        disabled={this.state.disabled}
                                        variant={"outlined"}
                                        color={"primary"}
                                        aria-label="toggle password visibility"
                                        onClick={this.handleClickToSearch}
                                    >
                                        Search
                                    </Button>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={4}>
                        <FormControl variant="standard">
                        <Input
                            id="input-with-icon-adornment"
                            placeholder="Search by Pincode"
                            // onChange={this.onSearch}
                            startAdornment={
                        <InputAdornment position="start">
                        <SearchIcon />
                        </InputAdornment>
                        }
                    />
                        </FormControl>
                     </Grid> */}
                    <Grid item xs={12} md={4} align="right">
                        <div>
                            <Button
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={this.handleDrawerOpen}
                                id={"add_partner_btn"}
                                style={{marginRight: "10px", lineHeight: "2"}}
                                //disabled={secureLocalStorage.access_type=== false ? true  : false}
                                disabled={secureLocalStorage.getItem("access_type") === false}
                            >
                                {" "}
                                Add{" "}
                            </Button>
                            {(secureLocalStorage.getItem("id") === 10 || secureLocalStorage.getItem("id") === 1454) && (
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={exportCSV}
                                    style={{ marginRight: 12 }}
                                >
                                    <ArrowUpwardIcon />
                                    Export
                                </Button>
                            )}
                        </div>
                    </Grid>
                </Grid>
                <SwipeableDrawer
                    anchor={"right"}
                    variant="temporary"
                    classes={{
                        paper: classes.drawerWidth
                    }}
                    open={this.state.drawerIsOpen}
                    onClose={this.handleDrawerClose}
                    onOpen={this.handleDrawerOpen}
                    ModalProps={{
                        BackdropProps: {
                            classes: {
                                root: classes.BackdropProps
                            }
                        }
                    }}
                >
                    <div className={classes.drawerHeading} style={{marginLeft: "10px"}}>
                        <Typography
                            variant="h2"
                            style={{marginTop: "5%", color: "#808080"}}
                        >
                            Add Partner
                        </Typography>
                    </div>
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={this.handleDrawerClose}>
                            <CloseButton/>
                        </IconButton>
                    </div>
                    <CreatePumpSingleSignOn
                        pumpCreationSuccessCallback={this.redirectOnSuccess}
                    />
                </SwipeableDrawer>
            </div>
        );
    }
}

UsersToolbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    selectedUsers: PropTypes.array
};

UsersToolbar.defaultProps = {
    selectedUsers: []
};

export default withStyles(styles)(UsersToolbar);
