import React, {Component} from "react";

// Externals
import PropTypes from "prop-types";
import classNames from "classnames";

// Material helpers
// Material components
import {Button, Dialog, DialogContent, Grid, Typography, withStyles} from "@material-ui/core";

// Material icons
import {ArrowUpward as ArrowUpwardIcon} from "@material-ui/icons";

// Shared components
import {SearchInput} from "components";
// import SelectDropdown from '../../../../components/SelectDropdown'
// Component styles
import styles from "./styles";
import CreateRefueler from "../CreateRefueler/CreateRefueler";
import {resetFormCreateRefueler} from "../../../../actions/actions";
import {connect} from "react-redux";
import compose from "recompose/compose";
import secureLocalStorage from "react-secure-storage";

class UsersToolbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchString: "",
            openDialogRPP: false
        };

        this.onSearch = this.onSearch.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    onSearch(searchTerm) {
        this.setState({
            selectedValue: searchTerm
        });
        const {searchedTerm} = this.props;
        searchedTerm(searchTerm);
    }

    onSelect(value) {
        const {selectedPartner} = this.props;
        selectedPartner(value);
    }

    openDialogForRpp = () => {
        this.setState({
            openDialogRPP: true
        });
    };

    handleClose() {
        this.setState(
            {
                openDialogRPP: false
            },
            () => {
                this.props.handleResetFormCreateRefueler();
            }
        );
    }

    render() {
        const {classes, className} = this.props;

        const rootClassName = classNames(classes.root, className);

        const exportCSV = () => {
            const csvRow = [];
            const A = [
                [
                    "Vehicle NNumber",
                    "Last Stock",
                    "Last Updated At",
                    "Partner",
                    "Partner Contact",
                    "Operator Contact",
                    "Status"
                ]
            ];
            const allRefuellerData = this.props.allRefuellerData;

            let firstNameForFileName = "";
            let lastNameForFileName = "";

            for (let item = 0; item < allRefuellerData?.length; item++) {
                A.push([
                    allRefuellerData[item]?.vehicle_registration_number,
                    allRefuellerData[item]?.last_stock === null
                        ? "No Data"
                        : allRefuellerData[item]?.last_stock,
                    allRefuellerData[item]?.updated_at,
                    allRefuellerData[item]?.partner_name,
                    allRefuellerData[item]?.partner_contact_number,
                    allRefuellerData[item]?.operator_contact_number,
                    allRefuellerData[item]?.is_active === true ? "Active" : "De-Active"
                ]);
                firstNameForFileName = allRefuellerData[0]?.vehicle_registration_number;
                lastNameForFileName =
                    allRefuellerData[allRefuellerData?.length - 1]
                        ?.vehicle_registration_number;
            }

            for (let i = 0; i < A.length; ++i) {
                csvRow.push(A[i].join(","));
            }

            const today = new Date();
            const date =
                today.getFullYear() +
                "-" +
                (today.getMonth() + 1) +
                "-" +
                today.getDate();
            const time =
                today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            const dateTime = date + "_" + time;

            const csvString = csvRow.join("%0A");

            const a = document.createElement("a");

            a.href = "data:attachement/csv," + csvString;

            a.target = "_Blank";

            a.download =
                "Refueller List" +
                firstNameForFileName +
                "_to_" +
                lastNameForFileName +
                "at" +
                dateTime +
                ".csv";

            document.body.appendChild(a);

            a.click();
        };

        return (
            <div className={rootClassName}>
                <div>
                    <Typography
                        className={classes.nameText}
                        variant="h1"
                        style={{fontWeight: 300}}
                    >
                        All Repos Petrol Pumps
                    </Typography>
                </div>
                <div className={classes.row}>
                    <span className={classes.spacer}/>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <SearchInput
                            className={classes.searchInput}
                            placeholder="Search by vehicle number, partner name"
                            getsearchterm={this.onSearch}
                        />
                    </Grid>
                    <Grid item xs={12} md={8} align="right">
                        <div>
                            <Button
                                className={classes.exportButton3}
                                size="small"
                                variant="outlined"
                                color={"primary"}
                                onClick={e => this.openDialogForRpp(e)}
                                disabled={secureLocalStorage.getItem("access_type") === false}
                            >
                                Add RPP
                            </Button>
                            {(secureLocalStorage.getItem("id") === 10 || secureLocalStorage.getItem("id") === 1454) && (
                                <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={exportCSV}
                                    style={{ marginRight: 12 }}
                                >
                                    <ArrowUpwardIcon />
                                    Export
                                </Button>
                            )}
                        </div>
                    </Grid>
                </Grid>
                <Dialog
                    fullWidth
                    open={this.state.openDialogRPP}
                    onClose={this.handleClose.bind(this)}
                >
                    <DialogContent>
                        <CreateRefueler/>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

UsersToolbar.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    selectedUsers: PropTypes.array
};

UsersToolbar.defaultProps = {
    selectedUsers: []
};

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        handleResetFormCreateRefueler: () => {
            dispatch(resetFormCreateRefueler());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(compose(withStyles(styles))(UsersToolbar));

// export default withStyles(styles)(UsersToolbar);
